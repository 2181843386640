<template>
  <section class="main">
    <div class="tool">
      <div class="row">
        <span class="label">课程id</span>
        <el-input v-model="subjectCourseId" placeholder="必填" class="input-text"></el-input>
      </div>
      <div class="row">
        <el-button type="primary" @click="onSearch">查询</el-button>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" highlight-current-row style="width: 100%">
        <el-table-column label="id" width="80">
          <template slot-scope="scope">
            <div v-html="scope.row.id"></div>
          </template>
        </el-table-column>
        <el-table-column label="题干">
          <template slot-scope="scope">
            <div v-html="scope.row.title"></div>
          </template>
        </el-table-column>
        <el-table-column label="选项">
          <template slot-scope="scope">
            <div v-html="scope.row.content"></div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="small" @click="onView(scope.$index, scope.row)">预览</el-button>
            <el-button size="small" @click="onEdit(scope.$index, scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-count="totalPage"
        @current-change="onChangePage"
      ></el-pagination>
    </div>
  </section>
</template>

<script>
import { tikuApi } from "@/api/tikuApi";
import { mdUtil } from "@/util/mdUtil";
export default {
  data() {
    return {
      baseUrl: "http://localhost:3000",
      currentPage: 1,
      totalPage: 1,

      subjectCourseId: 1,
      start: 0,
      len: 15,

      tableData: [
        {
          subjectCourseId: "",
          id: "",
          title: "",
          content: "",
        },
      ],
    };
  },
  components: {},
  mounted() {
    let md = this.$markdownIt.render(" # hhh");
    this.md = md;
    const IS_PROD = ["production", "prod"].includes(process.env.NODE_ENV);
    if (IS_PROD) {
      this.baseUrl = "http://cms.kaiweitalk.cn";
    }
    console.log(process.env.VUE_APP_ENV);
  },
  methods: {
    onSearch() {
      tikuApi.pagerMaterial(this.subjectCourseId, this.start, this.len).then((ret) => {
        console.log(ret);
        let { code, data, total, totalPage } = ret;
        if (code == 0) {
          console.log("ret", ret);
          this.totalPage = totalPage;
          for (let item of data) {
            item.content = mdUtil.richTextUnitToHtml(item.content);
          }
          this.tableData = data;
          console.log("table data", this.tableData);
        }
      });
    },
    onChangePage(pageNo) {
      this.currentPage = pageNo;
      this.start = (this.currentPage - 1) * this.len;
      this.onSearch();
    },
    onEdit(index, row) {
      let path = `/tiku/aida-tiku-material-add?subjectCourseId=${row.subjectCourseId}&id=${row.id}`;
      let routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },
    onView(index, row) {
      let path = `/tiku/aida-tiku-material-view?subjectCourseId=${row.subjectCourseId}&id=${row.id}`;
      let routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 95%;
  .tool {
    display: flex;
    flex-direction: column;
    .row {
      display: flex;
      flex-direction: row;
      margin: 10px 0;
      .label {
        width: 80px;
        min-height: 40px;
        display: inline-flex;
        align-items: center;
      }
      .input-text {
        width: 400px;
      }
    }
  }
  .table {
    width: 100%;
  }
  .footer {
    margin: 10px 0;
  }
}
</style>
